import React from 'react';
import styled from 'styled-components';

const Svg =styled.svg`
  width: 100%;
  max-width: ${props => props.maxWidth}px;
  height: auto;
`;
const Logo = ({width, background, type, color, style }) => {
  return (
    <div>
      {type === 'square' ? (
        <Svg
          aria-hidden="true"
          maxWidth={width}
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 180 176.03"
        >
          <defs>
            <style>{`.bkg{fill: #008ecf;}.logo{fill: ${color};}`}</style>
          </defs>
          <g data-name="ajn-logo-square">
            <g>
              {background && <rect className="bkg" width="180" height="176.03" />}
              <path
                className="logo"
                d="M21,132.33l-.39,2.24H18.12l2.41-11.37H23.7l2.07,11.37H23.24l-.37-2.24Zm1.64-2-.29-2.16c-.08-.64-.24-1.87-.34-2.58h0c-.12.71-.29,2-.39,2.58l-.34,2.16Z"
              />
              <path
                className="logo"
                d="M33,131.92c0,.88,0,1.8.07,2.65H30.78l-.13-.88h0a2.35,2.35,0,0,1-2,1c-1.64,0-2.29-1.18-2.29-3.49v-4.94H29v4.67c0,1,.22,1.59.71,1.59a.57.57,0,0,0,.52-.34,1.45,1.45,0,0,0,.15-.69v-5.23H33Z"
              />
              <path
                className="logo"
                d="M34.32,132.31a3.45,3.45,0,0,0,1.57.44c.49,0,.76-.17.76-.54s-.25-.54-.93-.91A2.62,2.62,0,0,1,34,128.9,2.71,2.71,0,0,1,37,126.14a4.23,4.23,0,0,1,1.77.37l-.4,1.89a2.55,2.55,0,0,0-1.18-.32c-.51,0-.73.25-.73.56s.19.46.94.88A2.69,2.69,0,0,1,39,132c0,1.72-1.25,2.68-3.12,2.68a3.94,3.94,0,0,1-2-.47Z"
              />
              <path
                className="logo"
                d="M42.91,124v2.24h1.64v1.82H42.91v3.31c0,.91.4,1.13.83,1.13a3.7,3.7,0,0,0,.61-.05v2a6.68,6.68,0,0,1-1.52.15,2.55,2.55,0,0,1-1.74-.54,3,3,0,0,1-.79-2.5v-3.54h-1v-1.82h1v-1.48Z"
              />
              <path
                className="logo"
                d="M45.45,128.92c0-.88,0-1.8-.07-2.65h2.24l.13,1.18h.07a1.89,1.89,0,0,1,1.6-1.32,2.3,2.3,0,0,1,.56.05v2.56a2.91,2.91,0,0,0-.57,0,1.3,1.3,0,0,0-1.23.94,2.61,2.61,0,0,0-.12.86v4H45.45Z"
              />
              <path
                className="logo"
                d="M56.63,132.67a11.26,11.26,0,0,0,.13,1.91H54.44l-.13-.79h0a2.31,2.31,0,0,1-1.91.93,2.37,2.37,0,0,1-2.28-2.58c0-2.23,2-3.1,3.9-3.12v-.15c0-.46-.42-.81-1.1-.81a3.39,3.39,0,0,0-1.82.51l-.46-1.74a6.3,6.3,0,0,1,2.85-.67c2.88,0,3.17,2.06,3.17,3.66Zm-2.56-2c-.76,0-1.53.32-1.53,1.2,0,.71.42,1,.71,1a.81.81,0,0,0,.78-.49,1,1,0,0,0,.05-.34Z"
              />
              <path className="logo" d="M57.92,122.9h2.61v11.67H57.92Z" />
              <path
                className="logo"
                d="M64.39,124.06a1.28,1.28,0,0,1-1.32,1.35,1.36,1.36,0,1,1,1.32-1.35Zm-2.56,10.51v-8.3h2.61v8.3Z"
              />
              <path
                className="logo"
                d="M71.84,132.67a11.26,11.26,0,0,0,.13,1.91H69.65l-.13-.79h0a2.31,2.31,0,0,1-1.91.93,2.37,2.37,0,0,1-2.28-2.58c0-2.23,2-3.1,3.9-3.12v-.15c0-.46-.42-.81-1.1-.81a3.39,3.39,0,0,0-1.82.51l-.46-1.74a6.3,6.3,0,0,1,2.85-.67c2.88,0,3.17,2.06,3.17,3.66Zm-2.56-2c-.76,0-1.53.32-1.53,1.2,0,.71.42,1,.71,1a.81.81,0,0,0,.78-.49,1,1,0,0,0,.05-.34Z"
              />
              <path
                className="logo"
                d="M73.11,128.92c0-.88,0-1.8-.07-2.65h2.24l.13.94h0a2.48,2.48,0,0,1,2.06-1.08c1.75,0,2.29,1.43,2.29,3.22v5.21H77.19v-5.13c0-.57-.15-1.11-.67-1.11a.72.72,0,0,0-.66.49,2.06,2.06,0,0,0-.13.78v5H73.11Z"
              />
              <path
                className="logo"
                d="M85.28,123.2H87.9v7.56c0,3.59-1.82,3.95-3.42,3.95a3.7,3.7,0,0,1-1.23-.19l.22-2.19a2.5,2.5,0,0,0,.76.1c.52,0,1.06-.22,1.06-1.53Z"
              />
              <path
                className="logo"
                d="M91.34,131.5c0,.76.67,1.21,1.67,1.21a5.66,5.66,0,0,0,1.67-.27L95,134.3a7.89,7.89,0,0,1-2.46.37c-2.38,0-3.71-1.43-3.71-4,0-2.83,1.59-4.49,3.54-4.49s3,1.25,3,4a7,7,0,0,1-.08,1.33Zm1.7-1.8c0-.81-.13-1.69-.81-1.69s-.89,1-.89,1.69Z"
              />
              <path
                className="logo"
                d="M98.37,126.27l.29,3.37c.08.91.17,1.7.22,2.46h.05c.12-.76.27-1.64.42-2.43l.64-3.41h2l.61,3.34c.17.94.3,1.74.39,2.5h0c.05-.74.12-1.62.22-2.5l.39-3.34h2.58l-2.16,8.3h-2.24l-.47-2.41a24.21,24.21,0,0,1-.4-2.63h0a21.06,21.06,0,0,1-.47,2.63l-.59,2.41H97.6l-2-8.3Z"
              />
              <path
                className="logo"
                d="M109.4,124.06a1.28,1.28,0,0,1-1.32,1.35,1.36,1.36,0,1,1,1.32-1.35Zm-2.56,10.51v-8.3h2.61v8.3Z"
              />
              <path
                className="logo"
                d="M110.77,132.31a3.45,3.45,0,0,0,1.57.44c.49,0,.76-.17.76-.54s-.25-.54-.93-.91a2.62,2.62,0,0,1-1.69-2.39,2.71,2.71,0,0,1,2.92-2.77,4.23,4.23,0,0,1,1.77.37l-.4,1.89a2.55,2.55,0,0,0-1.18-.32c-.51,0-.73.25-.73.56s.19.46.94.88a2.69,2.69,0,0,1,1.65,2.48c0,1.72-1.25,2.68-3.12,2.68a3.94,3.94,0,0,1-2-.47Z"
              />
              <path
                className="logo"
                d="M116.16,122.9h2.61v4.23h0a2.57,2.57,0,0,1,.73-.69,2.35,2.35,0,0,1,1.13-.3c1.72,0,2.19,1.5,2.19,3.61v4.82h-2.61v-4.91c0-.71-.12-1.33-.69-1.33-.27,0-.52.12-.67.56a3,3,0,0,0-.1.62v5.06h-2.61Z"
              />
              <path
                className="logo"
                d="M127.49,134.57V123.2h2.38l1.6,4.08c.29.73.83,2.16,1.11,3h0c-.07-.93-.25-3.14-.25-5.28V123.2h2.28v11.37h-2.38l-1.53-3.83a28.92,28.92,0,0,1-1.08-3.22h0c.05,1,.15,2.87.15,5.09v2Z"
              />
              <path
                className="logo"
                d="M137.92,131.5c0,.76.67,1.21,1.67,1.21a5.66,5.66,0,0,0,1.67-.27l.32,1.86a7.89,7.89,0,0,1-2.46.37c-2.38,0-3.71-1.43-3.71-4,0-2.83,1.59-4.49,3.54-4.49s3,1.25,3,4a7,7,0,0,1-.08,1.33Zm1.7-1.8c0-.81-.13-1.69-.81-1.69s-.89,1-.89,1.69Z"
              />
              <path
                className="logo"
                d="M144.95,126.27l.29,3.37c.08.91.17,1.7.22,2.46h.05c.12-.76.27-1.64.42-2.43l.64-3.41h2l.61,3.34c.17.94.3,1.74.39,2.5h0c.05-.74.12-1.62.22-2.5l.39-3.34h2.58l-2.16,8.3h-2.24l-.47-2.41a24.21,24.21,0,0,1-.4-2.63h0a21.06,21.06,0,0,1-.47,2.63l-.59,2.41h-2.24l-2-8.3Z"
              />
              <path
                className="logo"
                d="M153.31,132.31a3.45,3.45,0,0,0,1.57.44c.49,0,.76-.17.76-.54s-.25-.54-.93-.91A2.62,2.62,0,0,1,153,128.9a2.71,2.71,0,0,1,2.92-2.77,4.23,4.23,0,0,1,1.77.37l-.4,1.89a2.55,2.55,0,0,0-1.18-.32c-.51,0-.73.25-.73.56s.19.46.94.88A2.69,2.69,0,0,1,158,132c0,1.72-1.25,2.68-3.12,2.68a3.94,3.94,0,0,1-2-.47Z"
              />
              <path className="logo" d="M26.62,123.43h-.83V123H28v.38h-.84v2.13h-.53Z" />
              <path
                className="logo"
                d="M28.89,123v1h1.24v-1h.53v2.52h-.53v-1.11H28.89v1.11h-.53V123Z"
              />
              <path
                className="logo"
                d="M32.94,124.45h-1.1v.74h1.23v.38H31.3V123H33v.38H31.84v.65h1.1Z"
              />
              <path
                className="logo"
                d="M35.48,99.7,33,114.23H16.58L32.21,40.6H52.75l13.44,73.64H49.8L47.39,99.7Zm10.6-13-1.86-14C43.68,68.57,42.69,60.59,42,56h-.22c-.76,4.59-1.86,12.78-2.51,16.71l-2.19,14Z"
              />
              <path
                className="logo"
                d="M84,40.6h16.93V89.54c0,23.27-11.8,25.57-22.18,25.57a24,24,0,0,1-8-1.2l1.42-14.2a16.25,16.25,0,0,0,4.92.66c3.39,0,6.88-1.42,6.88-9.94Z"
              />
              <path
                className="logo"
                d="M110.21,114.23V40.6h15.4L136,67c1.86,4.7,5.35,14,7.21,19.67h.22c-.44-6-1.64-20.32-1.64-34.2V40.6h14.75v73.64h-15.4l-9.94-24.8a187.12,187.12,0,0,1-7-20.87H124c.33,6.34,1,18.57,1,33v12.67Z"
              />
            </g>
          </g>
        </Svg>
      ) : (
        <Svg
          aria-hidden="true"
          maxWidth={width}
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 767.67 89.47"
        >
          <g data-name="ajn-logo">
            <g>
              {background && <rect className="cls-1" width="767.67" height="89.47" />}
              <path
                className="cls-2"
                d="M29,63,27,74.91H13.57L26.33,14.76H43.11l11,60.15H40.7L38.73,63Zm8.66-10.62L36.14,41c-.45-3.39-1.25-9.91-1.78-13.66h-.18c-.62,3.75-1.52,10.44-2.05,13.66L30.34,52.42Z"
              />
              <path
                className="cls-2"
                d="M92.1,60.9c0,4.64.18,9.55.36,14H80.59l-.71-4.64H79.7a12.45,12.45,0,0,1-10.44,5.35c-8.66,0-12.14-6.25-12.14-18.47V31H70.95V55.72c0,5.27,1.16,8.39,3.75,8.39a3,3,0,0,0,2.77-1.79,7.66,7.66,0,0,0,.8-3.66V31H92.1Z"
              />
              <path
                className="cls-2"
                d="M99.33,62.95a18.25,18.25,0,0,0,8.3,2.32c2.59,0,4-.89,4-2.86s-1.34-2.86-4.91-4.82c-7.76-4.19-8.92-9.1-8.92-12.67,0-8.21,5.8-14.64,15.44-14.64a22.4,22.4,0,0,1,9.37,2l-2.14,10a13.48,13.48,0,0,0-6.25-1.7c-2.68,0-3.84,1.34-3.84,2.95s1,2.41,5,4.64c7.05,3.84,8.75,8.66,8.75,13.12,0,9.1-6.6,14.19-16.51,14.19-3.84,0-8.48-1-10.53-2.5Z"
              />
              <path
                className="cls-2"
                d="M144.76,19.13V31h8.66v9.64h-8.66V58.13c0,4.82,2.14,6,4.37,6a19.6,19.6,0,0,0,3.21-.27v10.8a35.34,35.34,0,0,1-8,.8c-3.66,0-6.87-.8-9.19-2.86-2.68-2.41-4.19-6-4.19-13.21V40.64h-5.09V31h5.09V23.14Z"
              />
              <path
                className="cls-2"
                d="M158.23,45c0-4.64-.18-9.55-.36-14h11.87l.71,6.25h.36c2.14-4.91,5.62-7,8.48-7a12.18,12.18,0,0,1,2.95.27V44.12a15.37,15.37,0,0,0-3-.18,6.87,6.87,0,0,0-6.52,5,13.81,13.81,0,0,0-.62,4.55V74.91H158.23Z"
              />
              <path
                className="cls-2"
                d="M217.4,64.82a59.58,59.58,0,0,0,.71,10.08H205.8l-.71-4.19h-.18a12.21,12.21,0,0,1-10.08,4.91c-7,0-12-5.8-12-13.66,0-11.78,10.71-16.42,20.62-16.51v-.8c0-2.41-2.23-4.28-5.8-4.28A17.92,17.92,0,0,0,188,43l-2.41-9.19a33.35,33.35,0,0,1,15.08-3.57c15.26,0,16.78,10.89,16.78,19.37ZM203.84,54.11c-4,.09-8.12,1.7-8.12,6.34,0,3.75,2.23,5.18,3.75,5.18A4.29,4.29,0,0,0,203.57,63a5,5,0,0,0,.27-1.79Z"
              />
              <path className="cls-2" d="M224.18,13.15H238V74.91H224.18Z" />
              <path
                className="cls-2"
                d="M258.46,19.31a6.8,6.8,0,0,1-7,7.14,6.67,6.67,0,0,1-6.6-7.14,6.73,6.73,0,0,1,6.78-7.23C255.42,12.08,258.46,14.84,258.46,19.31Zm-13.57,55.6V31h13.83V74.91Z"
              />
              <path
                className="cls-2"
                d="M297.9,64.82a59.58,59.58,0,0,0,.71,10.08H286.3l-.71-4.19h-.18a12.21,12.21,0,0,1-10.08,4.91c-7,0-12-5.8-12-13.66,0-11.78,10.71-16.42,20.62-16.51v-.8c0-2.41-2.23-4.28-5.8-4.28A17.92,17.92,0,0,0,268.45,43L266,33.85a33.35,33.35,0,0,1,15.08-3.57c15.26,0,16.78,10.89,16.78,19.37ZM284.34,54.11c-4,.09-8.12,1.7-8.12,6.34,0,3.75,2.23,5.18,3.75,5.18A4.29,4.29,0,0,0,284.07,63a5,5,0,0,0,.27-1.79Z"
              />
              <path
                className="cls-2"
                d="M304.6,45c0-4.64-.18-9.55-.36-14h11.87l.71,5H317a13.12,13.12,0,0,1,10.89-5.71c9.28,0,12.14,7.59,12.14,17V74.91H326.19V47.78c0-3-.8-5.89-3.57-5.89-1.52,0-2.68.89-3.48,2.59a10.93,10.93,0,0,0-.71,4.11V74.91H304.6Z"
              />
              <path
                className="cls-2"
                d="M369,14.76h13.83v40c0,19-9.64,20.88-18.12,20.88a19.6,19.6,0,0,1-6.51-1L359.39,63a13.25,13.25,0,0,0,4,.54c2.77,0,5.62-1.16,5.62-8.12Z"
              />
              <path
                className="cls-2"
                d="M401.07,58.67c.18,4,3.57,6.43,8.83,6.43a30,30,0,0,0,8.84-1.43l1.7,9.82a41.74,41.74,0,0,1-13,2c-12.58,0-19.63-7.59-19.63-21.42,0-15,8.39-23.74,18.74-23.74,9.64,0,16,6.6,16,21.33a37.23,37.23,0,0,1-.45,7.05Zm9-9.55c0-4.28-.71-8.92-4.28-8.92s-4.73,5.18-4.73,8.92Z"
              />
              <path
                className="cls-2"
                d="M438.29,31l1.52,17.85c.45,4.82.89,9,1.16,13h.27c.62-4,1.43-8.66,2.23-12.85l3.39-18h10.62l3.21,17.67c.89,5,1.61,9.19,2.05,13.21h.18c.27-3.93.63-8.57,1.16-13.21L466.13,31h13.65L468.36,74.91H456.49L454,62.15a128.12,128.12,0,0,1-2.14-13.92h-.18a111.46,111.46,0,0,1-2.5,13.92L446,74.91H434.18L423.38,31Z"
              />
              <path
                className="cls-2"
                d="M496.65,19.31a6.8,6.8,0,0,1-7,7.14,6.67,6.67,0,0,1-6.6-7.14,6.74,6.74,0,0,1,6.78-7.23C493.62,12.08,496.65,14.84,496.65,19.31Zm-13.57,55.6V31h13.83V74.91Z"
              />
              <path
                className="cls-2"
                d="M503.88,62.95a18.25,18.25,0,0,0,8.3,2.32c2.59,0,4-.89,4-2.86s-1.34-2.86-4.91-4.82c-7.76-4.19-8.92-9.1-8.92-12.67,0-8.21,5.8-14.64,15.44-14.64a22.4,22.4,0,0,1,9.37,2l-2.14,10a13.48,13.48,0,0,0-6.25-1.7c-2.68,0-3.84,1.34-3.84,2.95s1,2.41,5,4.64c7.05,3.84,8.75,8.66,8.75,13.12,0,9.1-6.6,14.19-16.51,14.19-3.84,0-8.48-1-10.53-2.5Z"
              />
              <path
                className="cls-2"
                d="M532.44,13.15h13.83v22.4h.18a13.58,13.58,0,0,1,3.84-3.66,12.46,12.46,0,0,1,6-1.61c9.1,0,11.6,7.94,11.6,19.1V74.91H554v-26c0-3.75-.62-7.05-3.66-7.05-1.43,0-2.77.63-3.57,2.95a16,16,0,0,0-.54,3.3V74.91H532.44Z"
              />
              <path
                className="cls-2"
                d="M592.41,74.91V14.76H605l8.48,21.6c1.52,3.84,4.37,11.42,5.89,16.07h.18c-.36-4.91-1.34-16.6-1.34-27.94V14.76h12V74.91H617.67l-8.12-20.26a153,153,0,0,1-5.71-17h-.18c.27,5.18.8,15.17.8,27V74.91Z"
              />
              <path
                className="cls-2"
                d="M647.56,58.67c.18,4,3.57,6.43,8.83,6.43a30,30,0,0,0,8.84-1.43l1.7,9.82a41.74,41.74,0,0,1-13,2c-12.58,0-19.63-7.59-19.63-21.42,0-15,8.39-23.74,18.74-23.74,9.64,0,16,6.6,16,21.33a37.23,37.23,0,0,1-.45,7.05Zm9-9.55c0-4.28-.71-8.92-4.28-8.92s-4.73,5.18-4.73,8.92Z"
              />
              <path
                className="cls-2"
                d="M684.78,31l1.52,17.85c.45,4.82.89,9,1.16,13h.27c.62-4,1.43-8.66,2.23-12.85l3.39-18H704l3.21,17.67c.89,5,1.61,9.19,2.05,13.21h.18c.27-3.93.63-8.57,1.16-13.21L712.63,31h13.65L714.86,74.91H703l-2.5-12.76a128.12,128.12,0,0,1-2.14-13.92h-.18a111.46,111.46,0,0,1-2.5,13.92l-3.12,12.76H680.67L669.87,31Z"
              />
              <path
                className="cls-2"
                d="M729,62.95a18.25,18.25,0,0,0,8.3,2.32c2.59,0,4-.89,4-2.86s-1.34-2.86-4.91-4.82c-7.76-4.19-8.92-9.1-8.92-12.67,0-8.21,5.8-14.64,15.44-14.64a22.4,22.4,0,0,1,9.37,2l-2.14,10a13.48,13.48,0,0,0-6.25-1.7c-2.68,0-3.84,1.34-3.84,2.95s1,2.41,5,4.64c7.05,3.84,8.75,8.66,8.75,13.12,0,9.1-6.6,14.19-16.51,14.19-3.84,0-8.48-1-10.53-2.5Z"
              />
              <path className="cls-2" d="M58.57,16H54.15v-2h11.7v2H61.4V27.27H58.57Z" />
              <path
                className="cls-2"
                d="M70.57,13.94v5.34h6.55V13.94H80V27.27H77.13V21.39H70.57v5.87H67.75V13.94Z"
              />
              <path
                className="cls-2"
                d="M92,21.35H86.17v3.92H92.7v2H83.34V13.94h9v2H86.17v3.44H92Z"
              />
            </g>
          </g>
        </Svg>
      )}
    </div>
  );
}
Logo.defaultProps = {
  width: 250,
  background: false,
  color: '#f8f8f8',
  type: 'square',
}
export default Logo;