import React, { Children } from 'react';
import { animated, config, Transition } from 'react-spring';
import { Helmet } from 'react-helmet';
import { Location } from '@reach/router';

import styled from 'styled-components';
import Nav from '../Nav';
import SubMenu from '../SubMenu';
import Footer from '../Footer';
import { HideOnMobile, HideOnDesktop } from '../../elements';

const C = styled.div`
  main {
    position: absolute;
    width: 100%;
    display: grid;
    height: auto;
    min-height: 100vh;
    grid-template-rows: 4rem 1fr 0;
    grid-template-columns: 1fr;
  }
`;

// so the footer isn't revealed on every route transition animation
// it breaks to leave animation tho
// const Backdrop =styled.div`
// position: absolute;
// top: 0;
// right: 0;
// left: 0;
// bottom: 0; */
// width: 100%;
// height: 100vh;
// background: white;
// `;

const Main = styled(animated.main)`
  /* offset for footer grid component height */
`;
const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
`;
const SkipToContent = styled.a`
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;
  &:focus,
  &:active {
    color: #fff;
    background-color: ${props => props.theme.dark};
    left: auto;
    top: auto;
    width: 30%;
    height: auto;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 15px;
    border: 4px solid ${props => props.theme.blue};
    text-align: center;
    font-size: 1.2em;
    z-index: 999;
  }
`;
const FooterContainer = styled(animated.div)`
  height: 100vh;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: -1;
  overflow-y: scroll;
  /* background-color: ${props => props.theme.dark}; */
`;

const FooterSpace = styled.div`
/* this sucks because if footer height changes (e.g adding another QuickLink) you gotta manually adjust these */
  visibility: hidden;
  @media screen and (min-width: 1037px) {
    height: 290px;
  }
  @media screen and (max-width: 1036px) {
    height: 580px;
  }
  @media screen and (max-width: 768px) {
    height: 0;
  }
`;
class AppContainer extends React.Component {
  render() {
    const { style, children, pageTitle } = this.props;
    return (
      <Location>
        {({ navigate, location }) => (
          <C>
            <Helmet>
              <title>{pageTitle || ''}</title>
            </Helmet>
            <Main
              style={
                style && {
                  transform: style.y.interpolate(y => `translate3d(0,${y}px,0)`),
                  ...style,
                }
              }
            >
              <SkipToContent href="#content">Skip to main content</SkipToContent>
              <Nav />
              <ContentContainer ariaRole="main">
                <HideOnMobile>
                  <SubMenu />
                </HideOnMobile>
                {children}
              </ContentContainer>
              <HideOnMobile>
                <FooterSpace />
              </HideOnMobile>
              <HideOnDesktop>
                <Footer style={{ position: 'relative' }} />
              </HideOnDesktop>
            </Main>
            <HideOnMobile>
              <Transition
                config={(item, type) => (type === 'leave' ? { duration: 1 } : config.default)}
                native
                // reset
                // unique
                items={location}
                keys={location => location.pathname}
                from={{
                  opacity: 0,
                  y: 250,
                  color: 'white',
                  delay: 0,
                }}
                enter={{
                  opacity: 1,
                  y: 0,
                  color: '#0b2945',
                  delay: 0,
                }}
                leave={{
                  opacity: 0,
                  y: 150,
                  color: 'white',
                  delay: 0,
                }}
              >
                {location => location
                  && (footerStyle => (
                    <FooterContainer ref={ref => (this.footerRef = ref)}>
                      <Footer style={footerStyle} />
                    </FooterContainer>
                  ))
                }
              </Transition>
            </HideOnMobile>
          </C>
        )}
      </Location>
    );
  }
}

AppContainer.defaultProps = {
  pageTitle: ' ',
};

export default AppContainer;
