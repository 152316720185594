import React, { Component } from 'react';
import ScrollableLink from '../ScrollableLink';
import MobileMenu from './MobileMenu';
import styled from 'styled-components';
import { Icon, HideOnDesktop, HideOnMobile } from '../../elements';
import Logo from '../Logo';
import { ContactInfo } from '../../queries';

const TopMenu = styled.nav`
  @media print {
    display: none;
  }
  height: 4rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${(props) => props.theme.dark};
  color: ${(props) => props.theme.light};
  z-index: 99;
  padding: 1rem;
  a {
    color: ${(props) => props.theme.light};
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
    transition: color 250ms ease;
    @media screen and (max-width: 400px) {
      font-size: 13px;
    }
    &:hover {
      color: ${(props) => props.theme.secondary};
      transition: color 250ms ease;
    }
    &.active {
      color: ${(props) => props.theme.secondary};
    }
  }
`;
const MenuToggle = styled.button`
  border: none;
  outline: none;
  color: white;
  background: none;
  font-size: 2rem;
  padding: 0.55rem 0.85rem;
`;

const MobileLogo = styled.div`
  margin-left: 1rem;
  @media screen and (min-width: 1201px) {
    display: none;
  }
`;
const DesktopLogo = styled.div`
  position: absolute;
  left: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateX(-50%);
  @media screen and (max-width: 1200px) {
    display: none;
  }
  > span {
    font-size: 0.8rem;
    letter-spacing: 1px;
  }
`;
const End = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.85rem;
`;

export const links = [
  {
    title: 'Home',
    label: 'home',
    to: '/',
    // exact: true,
  },
  {
    label: 'Overview',
    to: '/overview',
  },
  {
    label: 'Readership',
    to: '/readership',
  },
  {
    label: 'Upload artwork',
    to: '/upload',
  },
];

export default class Nav extends Component {
  state = {
    open: false,
  };
  trackPhone = () => {
    if (typeof window.ga === 'function') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Enquiry',
        eventAction: 'phone',
        eventLabel: 'phone link in Navbar',
      });
    }
  };
  trackEmail = () => {
    if (typeof window.ga === 'function') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Enquiry',
        eventAction: 'email',
        eventLabel: 'email link in Navbar',
      });
    }
  };
  toggle = () => this.setState(state => ({ open: !state.open }));
  render() {
    const icon = this.state.open ? 'close' : 'menu';
    return (
      <div>
        <TopMenu aria-label="navigation">
          <HideOnDesktop>
            <MenuToggle aria-label="Toggle menu" onClick={this.toggle}>
              <Icon>{icon}</Icon>
            </MenuToggle>
          </HideOnDesktop>
          <HideOnMobile>
            {links.map(({ label, icon, ...props }, i) => (
              <ScrollableLink type="navLink" key={i} {...props}>
                {icon && <Icon>{icon}</Icon>}
                {label}
              </ScrollableLink>
            ))}
          </HideOnMobile>
          <DesktopLogo>
            <Logo type="rectangle" style={{ maxWidth: 250, display: 'block', margin: '0 auto' }} />
            <span>Advertising</span>
          </DesktopLogo>

          <End>
            <HideOnMobile>
              <ContactInfo>
                {(phone, email) => (
                  <ContactContainer>
                    <span>
                      <Icon left>{phone.icon}</Icon>
                      <strong>
                        <a onClick={this.trackPhone} href={`tel:+61${phone.text.text.trim()}`}>
                          &nbsp;
                          {phone.text.text}
                        </a>
                      </strong>
                    </span>
                    <span>
                      <Icon left>{email.icon}</Icon>
                      <a onClick={this.trackEmail} href={`mailto:${email.text.text}`}>
                        {email.text.text}
                      </a>
                    </span>
                  </ContactContainer>
                )}
              </ContactInfo>
            </HideOnMobile>
            <MobileLogo>
              <Logo width={50} />
            </MobileLogo>
          </End>
        </TopMenu>
        <HideOnDesktop>
          <MobileMenu open={this.state.open} toggle={this.toggle} />
        </HideOnDesktop>
      </div>
    );
  }
}
