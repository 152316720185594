import React from 'react';
import styled from 'styled-components';
import { Panel } from '../../elements';
import SecondaryNav from '../SecondaryNav';

export const MenuPanel = styled(Panel)`
  grid-area: auto / span 2 / auto / auto;
  @media screen and (max-width: 768px) {
    grid-area: auto;
  }
  @media print {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
`;

const SubMenu = ({ history }) => {
  const navItems = [
    {
      to: '/print',
      label: 'Print',
    },
    {
      to: '/online',
      label: 'Online',
    },
    {
      to: '/app',
      label: 'iPad • iPhone • android',
    },
    {
      to: '/epaper',
      label: 'ePaper',
    },
  ];

  return (
    <MenuPanel color="light">
      <p>
        <strong>Choose a platform </strong>
        for more information
      </p>
      <SecondaryNav
        partialMatch
        items={navItems}
        color="light"
        backgroundColor="blue"
        hoverColor="dark"
        activeColor="dark"
      />
    </MenuPanel>
  );
};
export default SubMenu;
