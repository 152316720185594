export const config = {
  // this has been moved to contentful - see src/queries/Config
  //   sharefileIframeUrl: 'https://ajn.sharefile.com/remoteupload/edb9c0f6-d562-4d1c-9bf2-f0d72362a14b',
  //   propertyLink: 'https://propertyweekly.net.au/about/',
  //   subscribeLink: 'https://subscribe.jewishnews.net.au',
  //   csfLink: 'https://www.jewishnews.net.au/documents/Newsprinters.csf',
  //   graphqlEndpoint: 'https://pdfs.jewishnews.net.au/graphql',
  //   mediaKitFallback:
  //     'https://ajn-pdfs.s3.ap-southeast-2.amazonaws.com/pdfs/1531956681982-AJN_Media_kit_05-2017.pdf',
};
