import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Location } from '@reach/router';
import ScrollableLink from '../ScrollableLink';
import { Label } from '../../elements';

const Nav = styled.nav`
  padding-top: 1rem;
`;
const NavLabel = styled(Label)`
  font-size: ${props => `${props.size}rem`};
  padding: ${props => `${props.size / 2}rem ${props.size}rem`};
  border-radius: 1.7rem;
  box-shadow: ${props => (props.shadow ? null : '0px 0px 0px rgba(0,0,0,0)')};
`;
const NavLabelLink = styled(ScrollableLink)`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;
NavLabel.defaultProps = {
  size: 1.1,
  shadow: true,
};

const NavItem = ({
  size,
  item,
  color,
  backgroundColor,
  hoverColor,
  activeColor,
  currentRoute,
  partialMatch,
  shadow,
  isSticky,
}) => {
  const match = partialMatch ? currentRoute.startsWith(item.to) : currentRoute === item.to;
  return (
    <NavLabelLink to={item.to} className="no-border">
      <NavLabel
        size={size}
        shadow={shadow}
        color={color}
        backgroundColor={match ? activeColor : backgroundColor}
        hoverColor={hoverColor}
      >
        {item.label}
      </NavLabel>
    </NavLabelLink>
  );
};

const SecondaryNav = ({
  shadow,
  size,
  items,
  color,
  hoverColor,
  backgroundColor,
  activeColor,
  partialMatch,
  navRole,
}) => (
  <Location>
    {({ location }) => (
      <Nav aria-label="navigation">
        {items
          && items.map(item => (
            <NavItem
              key={item.to}
              shadow={shadow}
              size={size}
              partialMatch={partialMatch}
              item={item}
              color={color}
              backgroundColor={backgroundColor}
              hoverColor={hoverColor}
              activeColor={activeColor}
              currentRoute={location.pathname}
            />
          ))}
      </Nav>
    )}
  </Location>
);

SecondaryNav.propTypes = {
  items: PropTypes.array.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  hoverColor: PropTypes.string,
  activeColor: PropTypes.string,
  navRole: PropTypes.string,
};
SecondaryNav.defaultProps = {
  navRole: 'navigation',
};
export default SecondaryNav;
