import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { Link } from 'gatsby';
import { Icon } from '../../elements';
import Logo from '../Logo';
import { Consumer } from '../MediaKit';
import { config } from '../../config';
import { ItunesBadge, GooglePlayBadge, EpaperBadge } from '../AppBadges';
import { ContactInfo, QuickLinks } from '../../queries';
import ScrollableLink from '../ScrollableLink';

const FooterWrapper = styled.div`
  @media print {
    display: none;
  }
  background-color: ${(props) => props.theme.light};
`;
const FooterGrid = styled(animated.footer)`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: grid;
  max-height: 100vh;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 1036px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  @media screen and (max-width: 536px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    /* padding-top: 50vh; */
  }
  @media screen and (max-height: 500px) {
    /* padding-top: 50vh; */
  }
  grid-gap: 0rem;
  > div {
    font-size: 0.85rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    flex-wrap: wrap;
    > div {
      flex-direction: column;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
    }
    &:nth-of-type(1) {
      background: hsl(208, 25%, 84%);
    }
    &:nth-of-type(2) {
      background: hsl(208, 35%, 89%);
    }
    &:nth-of-type(3) {
      background: hsl(208, 45%, 93%);
    }
    &:last-of-type {
      /* align-items: flex-end; */
      background: hsl(208, 55%, 97%);
    }
  }
  h2 {
    color: ${(props) => props.theme.dark};
  }
  a {
    color: ${(props) => props.theme.dark};

    &:hover {
      color: ${(props) => props.theme.blue};
    }
    > span,
    svg {
      margin-right: 0.5rem;
    }
    /* color: white; */
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.75rem;
    border: none;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;
const SocialLogo = styled.svg`
  width: 25px;
  height: 25px;
`;

const Fb = (
  <SocialLogo
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    data-name="fb"
    x="0px"
    y="0px"
    width="266.893px"
    height="266.895px"
    viewBox="0 0 266.893 266.895"
    enableBackground="new 0 0 266.893 266.895"
    xmlSpace="preserve"
  >
    <path
      fill="#3C5A99"
      d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812  c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225  H248.082z"
    />
    <path
      fill="#FFFFFF"
      d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935  l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585  v99.803H182.409z"
    />
  </SocialLogo>
);

const Twitter = (
  <SocialLogo
    xmlnsDc="http://purl.org/dc/elements/1.1/"
    xmlnsCc="http://creativecommons.org/ns#"
    xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlnsSvg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    data-name="twitter"
    viewBox="0 0 300.00006 244.18703"
    height="244.18703"
    width="300.00006"
  >
    <g transform="translate(-539.17946,-568.85777)">
      <path
        fill="#1da1f2"
        d="m 633.89823,812.04479 c 112.46038,0 173.95627,-93.16765 173.95627,-173.95625 0,-2.64628 -0.0539,-5.28062 -0.1726,-7.90305 11.93799,-8.63016 22.31446,-19.39999 30.49762,-31.65984 -10.95459,4.86937 -22.74358,8.14741 -35.11071,9.62551 12.62341,-7.56929 22.31446,-19.54304 26.88583,-33.81739 -11.81284,7.00307 -24.89517,12.09297 -38.82383,14.84055 -11.15723,-11.88436 -27.04079,-19.31655 -44.62892,-19.31655 -33.76374,0 -61.14426,27.38052 -61.14426,61.13233 0,4.79784 0.5364,9.46458 1.58538,13.94057 -50.81546,-2.55686 -95.87353,-26.88582 -126.02546,-63.87991 -5.25082,9.03545 -8.27852,19.53111 -8.27852,30.73006 0,21.21186 10.79366,39.93837 27.20766,50.89296 -10.03077,-0.30992 -19.45363,-3.06348 -27.69044,-7.64676 -0.009,0.25652 -0.009,0.50661 -0.009,0.78077 0,29.60957 21.07478,54.3319 49.0513,59.93435 -5.13757,1.40062 -10.54335,2.15158 -16.12196,2.15158 -3.93364,0 -7.76596,-0.38716 -11.49099,-1.1026 7.78383,24.2932 30.35457,41.97073 57.11525,42.46543 -20.92578,16.40207 -47.28712,26.17062 -75.93712,26.17062 -4.92898,0 -9.79834,-0.28036 -14.58427,-0.84634 27.05868,17.34379 59.18936,27.46396 93.72193,27.46396"
      />
    </g>
  </SocialLogo>
);

const Instagram = (
  <SocialLogo
    xmlnsDc="http://purl.org/dc/elements/1.1/"
    xmlnsCc="http://creativecommons.org/ns#"
    xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlnsSvg="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 24 24"
    height="50"
    width="50"
    fill="#cd486b"
  >
    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
  </SocialLogo>
);

const scrollTop = () => {
  document.getElementById('content').scrollIntoView();
};

const Footer = ({ style }) => {
  const trackEmail = () => {
    if (typeof window.ga === 'function') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Enquiry',
        eventAction: 'email',
        eventLabel: 'email link in Footer',
      });
    }
  };
  const trackPhone = () => {
    if (typeof window.ga === 'function') {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Enquiry',
        eventAction: 'phone',
        eventLabel: 'phone link in Footer',
      });
    }
  };

  return (
    <Consumer>
      {(mediaKit) => (
        <FooterWrapper>
          <FooterGrid role="contentinfo" style={{ ...style }}>
            <div>
              <h2>Contact</h2>
              <a title="jewishnews.net.au" href="https://www.jewishnews.net.au">
                <Logo width="200" color="#008ecf" type="rect" />
              </a>
              <ContactInfo>
                {(phone, email) => (
                  <React.Fragment>
                    <a onClick={trackPhone} href={`tel:+61${phone.text.text}`}>
                      <Icon>{phone.icon}</Icon> {phone.text.text}
                    </a>
                    <a onClick={trackEmail} href={`mailto:${email.text.text}`}>
                      <Icon>{email.icon}</Icon> {email.text.text}
                    </a>
                  </React.Fragment>
                )}
              </ContactInfo>
              <small style={{ marginTop: 16 }}>
                <ScrollableLink to="/terms-and-conditions">
                  Advertising Terms & Conditions
                </ScrollableLink>
              </small>
            </div>
            <QuickLinks>
              {({ title, listItem }) => (
                <div>
                  <h2>Quick links</h2>
                  {listItem.map((item, i) => (
                    <Link key={i} onClick={scrollTop} to={item.text.text}>
                      <Icon>{item.icon}</Icon>
                      {item.title}
                    </Link>
                  ))}

                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={mediaKit ? mediaKit.realShortLink : config.mediaKitFallback}
                  >
                    <Icon>picture_as_pdf</Icon> Media kit PDF
                  </a>
                </div>
              )}
            </QuickLinks>
            <div>
              <h2>Social</h2>
              <div>
                <a
                  title="facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://facebook.com/australianjewishnews"
                >
                  {Fb} Facebook
                </a>
                <a
                  title="twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/aus_jewishnews"
                >
                  {Twitter} Twitter
                </a>
                <a
                  title="instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://instagram.com/jewishnews_au"
                >
                  {Instagram} Instagram
                </a>
              </div>
            </div>
            <div>
              <h2>Our apps</h2>
              <div>
                <ItunesBadge />
                <GooglePlayBadge />
                <EpaperBadge />
              </div>
            </div>
          </FooterGrid>
        </FooterWrapper>
      )}
    </Consumer>
  );
};

export default Footer;
