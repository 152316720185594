import React from 'react';
import styled from 'styled-components';
import GpBadgeLogo from './images/google-play-badge.png';
const Epaper =styled.span`
  font-size: 0.875rem;
  color: #f8f8f8;
  padding: 0.65rem 1rem;
  cursor: pointer;
  border-radius: 6px;
  background: black;
  text-align: center;
`;

const Itunes =styled.a`
  display: inline-block;
  overflow: hidden;
  background: url('//linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg') no-repeat;
  width: 120px;
  height:40px;
  background-size: contain;
`;
const PlayImg =styled.img`max-width: 120px;`;

export const ItunesBadge = () => (
  <Itunes
    className="no-border" 
    target="_blank" 
    href="https://itunes.apple.com/au/app/jewish-news/id456941751?mt=8"
    rel="noopener noreferrer"
    aria-label="Download on the App Store"
  />
);

export const GooglePlayBadge = () => (
  <a 
    className="no-border" 
    target="_blank" 
    href="https://play.google.com/store/apps/details?id=com.pagesuite.australianjewishnews"
    rel="noopener noreferrer"
  >
    <PlayImg src={GpBadgeLogo} alt="AJN android app" />
  </a>

)
export const EpaperBadge = () => (
  <a href="http://edition.jewishnews.net.au">
    <Epaper>
      html5 ePaper
    </Epaper>
  </a>
)
