import React, { Component } from 'react';
import { Keyframes, animated } from 'react-spring';
import styled from 'styled-components';
import ScrollableLink from '../../ScrollableLink';
import SubMenu from '../../SubMenu';
import { Consumer } from '../../MediaKit';
import { config } from '../../../config';

const MenuContainer = styled(animated.div)`
  position: absolute;
  background-color: ${props => props.theme.darker};
  color: white;
  padding: 0rem;
  height: 100%;
  z-index: 99;
`;
const Links = styled.div`
  margin: 1rem 0;
`;

const MenuItem = styled(animated.div)`
  > a {
    display: block;
    padding: 0.6rem 2rem;
    color: white;
    border: none;
    font-size: 1.1rem;
  }
`;

const Sidebar = Keyframes.Spring({
  open: { x: 0, visibility: 'visible', delay: 0 },
  close: { x: -100, visibility: 'hidden', delay: 700 },
});

const MenuItems = Keyframes.Trail({
  open: { x: 0, opacity: 1, delay: 100 },
  close: { x: -100, opacity: 0 },
});
const SubWrapper = Keyframes.Spring({
  open: { opacity: 1, delay: 250 },
  close: { opacity: 0 },
});

const mobileLinks = [
  {
    icon: 'home',
    title: 'Home',
    label: 'home',
    to: '/',
    // exact: true,
  },
  {
    label: 'Overview',
    to: '/overview',
  },
  {
    label: 'Readership',
    to: '/readership',
  },
  {
    label: 'Upload artwork',
    to: '/upload',
  },
  {
    label: 'Download Media Kit',
  },
];
export default class MobileMenu extends Component {
  render() {
    const state = this.props.open ? 'open' : 'close';
    return (
      <Consumer>
        {mediaKit => (
          <React.Fragment>
            <Sidebar native state={state}>
              {({ x, ...props }) => (
                <MenuContainer
                  style={{
                    transform: x.interpolate(x => `translate3d(${x}%,0,0)`),
                    ...props,
                  }}
                >
                  <SubWrapper native state={state}>
                    {({ opacity }) => (
                      <animated.div
                        style={{
                          opacity,
                        }}
                      >
                        <SubMenu />
                      </animated.div>
                    )}
                  </SubWrapper>
                  <Links>
                    <MenuItems
                      native
                      items={mobileLinks}
                      keys={mobileLinks.map((_, i) => i)}
                      reverse={!this.props.open}
                      state={state}
                    >
                      {({ label, icon, ...itemProps }, i) => ({ x, ...props }) => (
                        <MenuItem
                          style={{
                            transform: x.interpolate(x => `translate3d(${x}%,0,0)`),
                            ...props,
                          }}
                        >
                          {itemProps.to ? (
                            <ScrollableLink type="navLink" {...itemProps}>
                              {label}
                            </ScrollableLink>
                          ) : (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={mediaKit ? mediaKit.realShortLink : config.mediaKitFallback}
                            >
                              {label}
                            </a>
                          )}
                        </MenuItem>
                      )}
                    </MenuItems>
                  </Links>
                </MenuContainer>
              )}
            </Sidebar>
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}
